import { getPatientList } from '@/apis/user'
// import isEqual from 'lodash.isequal'

const getDefaultState = () => (
  {
    loading: {
      status: false,
      time: null,
      orgData: null
    },
    normalList: []
  }
)

const state = getDefaultState()

const getters = {
  getNormalList: state => {
    return state.normalList
  },
  getDefaultPatientId: state => {
    return getDefaultPatientId(state.normalList)
  },
  getLoadingStatus: state => {
    return state.loading.status
  },
  getLoadingTime: state => {
    return state.loading.time
  }
}

const mutations = {
  SET_LOADING_INFO: (state, { status = false, time = new Date().getTime(), orgData = null }) => {
    state.loading.status = status
    state.loading.time = time
    state.loading.orgData = orgData
  },
  SET_PATIENT_LIST: (state, list) => {
    if (!list || list.length == 0) return
    state.normalList = list
  },
  // 重置 state
  RESET_STATE: (state) => {
    Object.assign(state, getDefaultState())
  }
}

const actions = {
  getPatientList ({ commit }) {
    return new Promise((resolve, reject) => {
      getPatientList()
        .then(response => {
          const { data } = response

          if (!response.data) {
            reject(new Error('[CZN error]: Patient list API has no data !!'))
          }

          // 如果已經掉用過 api ，檢查 待處理列表(todo) 是否相同  不同 -> resetHomeMessages
          // if (state.loading.status) {
          //   const { orgData } = state.loading
          //   if (!isEqual(todo, orgData.todo)) {
          //     commit('main/resetHomeMessages', null, { root: true })
          //   }
          // }

          commit('SET_PATIENT_LIST', data)
          commit('SET_LOADING_INFO', { status: true, orgData: response.data })

          resolve()
        })
        .catch(error => reject(error))
    })
  }
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}

// 獲取初次進入病患管理的病人ID， 進行中病人 -> 待處理 -> 已結束
function getDefaultPatientId (normalList) {
  const [ firstNormalListPatient ] = normalList
  // const [ firstPendingListPatient ] = pendingList
  // const [ firstCompleteListPatient ] = completeList
  if (firstNormalListPatient) return firstNormalListPatient.id
  // if (firstPendingListPatient) return firstPendingListPatient.id
  // if (firstCompleteListPatient) return firstCompleteListPatient.id
  return ''
}

// function buildUserObject (object, key) {
//   return {
//     id: key,
//     name: object[key].from,
//     avatar: object[key].avatar,
//     object: object[key].object,
//     type: object[key].type || '',
//     time: object[key].time || 0
//   }
// }

// function sortByTime (a, b) {
//   if (b.time < a.time) {
//     return -1
//   } else if (b.time > a.time) {
//     return 1
//   }

//   return 0
// }
