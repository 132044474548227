import i18n from '@/lang'
// import isEqualWith from 'lodash.isequalwith'
// import isEqual from 'lodash.isequal'
// import cloneDeep from 'lodash.clonedeep'

export const DEFAULT_TIMEZONE = 'Asia/Taipei'

/**
 * A const is used for sending headers with JSON type.
 */
export let JSON_CONFIG = {
  'Content-Type': 'application/json'
}

/**
 * A const is used for sending headers with x-www-form-urlencoded type.
 */
export let FORM_CONFIG = {
  'Content-Type': 'application/x-www-form-urlencoded;charset=utf-8'
}

// /**
//  * A helper function to check level is valid or not.
//  * @param {object} level - The level object
//  */
// export function levelIsValid (level) {
//   if (level.level === 0) return false
//   if (level.period === 0) return false
//   if (level.round === 0) return false
//   if (level.targets.length === 0) return false
//   if (level.exercisesTmp.length === 0) return false

//   let targetOfPeriodIsEmpty = true
//   for (let index = 0; index < level.targets.length; index++) {
//     if (level.targets[index].flexion == null || level.targets[index].extension == null) {
//       targetOfPeriodIsEmpty = false
//       break
//     }
//   }
//   if (!targetOfPeriodIsEmpty) return false

//   let dayOfUpTargetIsEmpty = true
//   for (let index = 0; index < level.lv_up_targets.length; index++) {
//     if (
//       level.lv_up_targets[index].max == null &&
//       level.lv_up_targets[index].category !== 'check'
//     ) {
//       dayOfUpTargetIsEmpty = false
//       break
//     }
//   }
//   if (!dayOfUpTargetIsEmpty) return false

//   let noneCheckLevelUpTarget = level.lv_up_targets.filter(v => v.category !== 'check')
//   if (noneCheckLevelUpTarget.length === 0) return false

//   let roundIsNotEmpty = true
//   for (let round = 0; round < level.exercisesTmp.length; round++) {
//     if (level.exercisesTmp[round].length === 0) {
//       roundIsNotEmpty = false
//       break
//     }
//   }
//   if (!roundIsNotEmpty) return false

//   return true
// }

// /**
//  * A helper function to check level is valid or not.
//  * @param {object} template - The method of calling API.
//  */
// export function templateIsFinish (template) {
//   if (template.title.trim() === '') return false
//   if (template.advice.trim() === '') return false
//   if (template.careTips.length === 0) return false
//   if (template.level.length === 0) return false
//   if (template.mustKnow.length === 0) return false
//   if (template.situations.length === 0) return false

//   let levelIsFinish = true
//   for (let index = 0; index < template.level.length; index++) {
//     if (template.level[index].period == null ||
//       template.level[index].period < 1) {
//       levelIsFinish = false
//       break
//     }

//     if (template.level[index].targets == null ||
//       template.level[index].targets.length === 0) {
//       levelIsFinish = false
//       break
//     }

//     if (template.level[index].rehabInfos == null ||
//       template.level[index].rehabInfos.length === 0) {
//       levelIsFinish = false
//       break
//     }
//   }

//   return levelIsFinish
// }

export function accountType (type) {
  let typeList = [i18n.t('roles.medical'), i18n.t('roles.physicalTherapist')]
  return typeList[type]
}

// /**
//  * A helper function to check the given date in first argument
//  * is after the second argument or not.
//  * @param {string} comparableDate - A string to compare the date
//  *    that is after the second argument or not.
//  * @param {string} date - A string to be used for compar
//  * @return {boolean} This returns somethings that is after the
//  *    second argument or not
//  */
// export function isAfterDate (comparableDate, date = null) {
//   let comparableTimestamp = new Date(comparableDate).getTime()
//   let timestamp = date == null ? new Date().getTime() : new Date(date).getTime()
//   return comparableTimestamp > timestamp
// }

// /**
//  * A function to convert {YYYYMMDD} date format to {YYYY-MM-DD}.
//  * @param {string} dateStr - A string with {YYYYMMDD} format
//  * @param {string} separator - A string to combine Year, Month, Date
//  * @return {string} This returns a string that has alerady be formatted
//  */
// export function concatDateString (dateStr, separator = '-') {
//   return dateStr.substring(0, 4) +
//     separator +
//     dateStr.substring(4, 6) +
//     separator +
//     dateStr.substring(6, 8)
// }

/**
 * A function to get browser language or default language.
 */
export function getLanguageSetting () {
  const browserLang = navigator.language || navigator.userLanguage
  const supportLang = [ 'en-US', 'zh-CN', 'zh-TW' ]
  const settingLang = typeof localStorage.getItem === 'function' ? localStorage.getItem('locale') : null
  const defaultLang = 'en-US'

  if (settingLang) return settingLang

  for (let i = 0, length = supportLang.length; i < length; i++) {
    const lang = supportLang[i]
    if (browserLang.toLowerCase() === lang.toLowerCase()) return lang
  }

  return defaultLang
}

export function getAPILangParam () {
  return getLanguageSetting().replace('-', '_')
}

// // NOTE: 判斷每輪動作是否相同
// export function isSameRound (exercises) {
//   let result = true

//   for (let i = 1; i < exercises.length; i++) {
//     if (!isEqualWith(
//       exercises[0],
//       exercises[i],
//       customizer)
//     ) {
//       result = false
//       break
//     }
//   }

//   return result

//   function customizer (objValue, othValue) {
//     if (objValue.length !== othValue.length) return false

//     let status = true
//     for (let index = 0; index < objValue.length; index++) {
//       let same = objValue[index].rehabId === othValue[index].rehabId &&
//         isEqual(objValue[index].sensors, othValue[index].sensors) &&
//         isEqual(objValue[index].set, othValue[index].set)
//       if (!same) {
//         status = false
//         break
//       }
//     }
//     return status
//   }
// }

// // NOTE: 合併原始動作和改動的動作參數
// export function handleMergeExerciseAndConfig (exercises, rehabInfos) {
//   if (exercises.length === 0) return []
//   // FIXME: 先拔掉 cloneDeep ，修復改動動作等級 exerciseTmp 沒有更新的問題
//   // let cloneExercises = cloneDeep(exercises)
//   let cloneExercises = exercises
//   for (let roundIndex = 0; roundIndex < cloneExercises.length; roundIndex++) {
//     for (let exerIndex = 0; exerIndex < cloneExercises[roundIndex].length; exerIndex++) {
//       cloneExercises[roundIndex][exerIndex] = Object.assign({}, cloneExercises[roundIndex][exerIndex], rehabInfos[roundIndex][exerIndex])
//     }
//   }
//   return cloneExercises
// }

// export function calculateRehabInfos (exercisesTmp) {
//   let result = []

//   for (let exercisesArr of exercisesTmp) {
//     let roundOfExercises = exercisesArr.map((exercise) => {
//       return {
//         relateId: exercise.rehabId,
//         restDuration: 60,
//         sensorFixed: exercise.sensorFixed,
//         sensors: exercise.sensors,
//         set: exercise.set,
//         configModified: exercise.configModified || false
//       }
//     })

//     result.push(roundOfExercises)
//   }

//   return result
// }

// export function formatMediaLanguage (lang) {
//   switch (lang) {
//     case 'zh-hant':
//     case 'zht':
//     case 'zho':
//       return 'zh_TW'
//     case 'zh-hans':
//     case 'zhs':
//       return 'zh_CN'
//     case 'eng':
//       return 'en_US'
//   }
// }
