import Vue from 'vue'
import Router from 'vue-router'
import Main from './main'
import Patient from './patient'
import Line from './line'

Vue.use(Router)

var router = new Router({
  mode: 'history',
  base: process.env.VUE_APP_ASSETS_PUBLIC_PATH ? process.env.VUE_APP_ASSETS_PUBLIC_PATH : '/',
  routes: [
    ...Main,
    ...Patient,
    ...Line
  ]
})

// router.beforeEach(async (to, from, next) => {
//   next()
// })

export default router

