export default [
  {
    path: '/',
    name: 'home',
    component: () => import('@/pages/Home'),
    meta: {
      title: "首頁",
      requireAuth: true,
      isSupportMobile: true
    }
  },
  {
    path: '/login',
    name: 'user-login',
    component: () => import('@/pages/Login'),
    meta: {
      title: "登入",
      isSupportMobile: true
    }
  },
  {
    path: '/auth/token',
    name: 'user-token-auth',
    component: () => import('@/pages/LoginAuth'),
    meta: {
      title: "登入校驗",
      isSupportMobile: true
    }
  },
  {
    path: '/contest',
    name: 'contest-management',
    component: () => import('@/pages/Contest'),
    meta: {
      title: "量測管理",
      isSupportMobile: true
    }
  },
  {
    path: '/report/:token',
    name: 'user-report',
    component: () => import('@/pages/Report'),
    meta: {
      title: "量測報告",
      isSupportMobile: true
    }
  },
  {
    path: '/report/:token/statistics',
    name: 'user-report-statistics',
    component: () => import('@/pages/ReportStatistics'),
    meta: {
      title: "量測歷史紀錄",
      isSupportMobile: true
    }
  },
  {
    path: '/appointment',
    name: 'appointment',
    component: () => import('@/pages/Appointment'),
    meta: {
      title: "預約管理",
      isSupportMobile: true
    }
  },
]