import { getPatientProfile } from '@/apis/patient.js'
import { DEFAULT_TIMEZONE } from '@/utils/helper'

const getDefaultState = () => (
  {
    patientInfo: {
      id: '',
      name: '',
      birthday: '',
      // age: 0,
      idNumber: '',
      avatar: '',
      // gender: '',
      medicalPersonnel: {
        id: '',
        name: '',
        avatar: '',
        type: 0
      },
      medicalPersonIds: [],
      detail: {
        height: 0,
        weight: 0,
      },
      registerTime: 0,
      timezone: DEFAULT_TIMEZONE,
    },
  }
)

const state = getDefaultState()

const getters = {
  getPatientInfo: state => {
    return state.patientInfo
  },
}

const mutations = {
  SET_PATIENT_INFO: (state, patientInfo) => {
    Object.assign(state.patientInfo, patientInfo)
  },
  // 重置 state
  RESET_STATE: (state) => {
    Object.assign(state, getDefaultState())
  }
}

const actions = {
  getPatientProfile({ commit }, pid) {
    return new Promise((resolve, reject) => {
      getPatientProfile(pid)
        .then(response => {
          const { data } = response

          if (!response.data) {
            reject(new Error('[CZN error]: Patient Info API has no data !!'))
          }

          commit('SET_PATIENT_INFO', data)

          resolve(response)
        })
        .catch(error => reject(error))
    })
  }
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}