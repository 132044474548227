// USAGE: 提示組件
// this.$store.dispatch('notify/setMessage', { text: '', timeout= '5000', undoFunction = null })
// this.$store.dispatch('notify/setErrorMessage', { text: '', timeout= '5000' })
// this.$store.dispatch('notify/setToastMessage', text)

const getDefaultState = () => (
  {
    snackbar: {
      show: false,
      message: '',
      timeout: 10000,
      undoFunction: null,
      timer: null
    },
    errorSnackbar: {
      show: false,
      message: '',
      timeout: 5000,
      timer: null
    },
    toastMessage: ''
  }
)

const state = getDefaultState()

const mutations = {
  SET_MESSAGE: (state, message) => {
    state.snackbar.message = String(message)
  },
  SET_MESSAGE_TIMEOUT: (state, timeout) => {
    state.snackbar.timeout = parseInt(timeout)
  },
  SET_UNDO_FUNCTION: (state, undoFunction) => {
    state.snackbar.undoFunction = undoFunction
  },
  SHOW_MESSAGE: (state, timer = null) => {
    state.snackbar.show = true
    clearTimeout(state.snackbar.timer)
    state.snackbar.timer = timer
  },
  CLOSE_MESSAGE: (state) => {
    state.snackbar.show = false
  },
  SET_ERROR_MESSAGE: (state, errorMessage) => {
    state.errorSnackbar.message = String(errorMessage)
  },
  SET_ERROR_MESSAGE_TIMEOUT: (state, timeout) => {
    state.errorSnackbar.timeout = parseInt(timeout)
  },
  SET_TOAST_MESSAGE: (state, toastMessage) => {
    state.toastMessage = toastMessage
  },
  SHOW_ERROR_MESSAGE: (state, timer = null) => {
    state.errorSnackbar.show = true
    clearTimeout(state.errorSnackbar.timer)
    state.errorSnackbar.timer = timer
  },
  CLOSE_ERROR_MESSAGE: (state) => {
    state.errorSnackbar.show = false
  },
  // 重置 state
  RESET_STATE: (state) => {
    Object.assign(state, getDefaultState())
  }
}

const actions = {
  // set snackbar message
  setMessage ({ commit, dispatch }, { text = '', undoFunction = null, timeout = 10000 }) {
    if (!text) return
    return new Promise(resolve => {
      commit('CLOSE_MESSAGE')
      setTimeout(() => {
        const timer = setTimeout(() => {
          dispatch('resetMessage')
        }, timeout)

        commit('SET_MESSAGE', text)
        commit('SET_UNDO_FUNCTION', undoFunction)
        commit('SET_MESSAGE_TIMEOUT', timeout)
        commit('SHOW_MESSAGE', timer)

        resolve()
      }, 200)
    })
  },
  resetMessage ({ commit }) {
    return new Promise(resolve => {
      commit('SET_MESSAGE', '')
      commit('SET_UNDO_FUNCTION', null)
      commit('CLOSE_MESSAGE')
      resolve()
    })
  },
  // set error snackbar message
  setErrorMessage ({ commit, dispatch }, { text = '', timeout = 5000 }) {
    return new Promise(resolve => {
      commit('CLOSE_ERROR_MESSAGE')

      setTimeout(() => {
        const timer = setTimeout(() => {
          dispatch('resetErrorMessage')
        }, timeout)

        commit('SET_ERROR_MESSAGE', text)
        commit('SET_ERROR_MESSAGE_TIMEOUT', timeout)
        commit('SHOW_ERROR_MESSAGE', timer)
        resolve()
      })
    })
  },
  resetErrorMessage ({ commit }) {
    return new Promise(resolve => {
      commit('SET_ERROR_MESSAGE', '')
      commit('CLOSE_ERROR_MESSAGE')
      resolve()
    })
  },
  // set toast message
  setToastMessage ({ commit }, text) {
    return new Promise(resolve => {
      commit('SET_TOAST_MESSAGE', text)
      resolve()
    })
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
