import MobileDetect from 'mobile-detect'

const getDefaultState = () => (
  {
    mobileDetect: null,
    isShowMobileNotification: false,
    isShowMobileSimpleNotification: false,
    screenWidth: 0,
    screenHeight: 0,
    phoneBreakPoint: 576,
    tabletBreakPoint: 880
  }
)

const state = getDefaultState()

const getters = {
  isMobile: state => {
    return state.mobileDetect && state.mobileDetect.mobile()
  },
  isTablet: state => {
    return state.mobileDetect && state.mobileDetect.tablet()
  },
  isIos: state => {
    return state.mobileDetect && state.mobileDetect.is('iPhone')
  },
  userAgent: state => {
    return state.mobileDetect && state.mobileDetect.userAgent()
  },
  getScreenWidth: state => {
    return state.screenWidth
  },
  getScreenHeight: state => {
    return state.screenHeight
  },
  getPhoneBreakPoint: state => {
    return state.phoneBreakPoint
  },
  isMobileWidth: state => {
    return state.screenWidth < state.phoneBreakPoint
  },
  isTabletWidth: state => {
    return state.screenWidth < state.tabletBreakPoint
  },
  isShowMobileNotification: state => {
    return state.isShowMobileNotification
  },
  isShowMobileSimpleNotification: state => {
    return state.isShowMobileSimpleNotification
  }
}

const mutations = {
  SET_MOBILE_DETECT: (state, mobileDetect) => {
    state.mobileDetect = mobileDetect
  },
  SET_SCREEN_WIDTH: (state, screenWidth) => {
    state.screenWidth = screenWidth
  },
  SET_SCREEN_HEIGHT: (state, screenHeight) => {
    state.screenHeight = screenHeight
  },
  SHOW_MOBILE_NOTIFICATION: (state) => {
    state.isShowMobileNotification = true
  },
  CLOSE_MOBILE_NOTIFICATION: (state) => {
    state.isShowMobileNotification = false
  },
  SHOW_MOBILE_SIMPLE_NOTIFICATION: (state) => {
    state.isShowMobileSimpleNotification = true
  },
  CLOSE_MOBILE_SIMPLE_NOTIFICATION: (state) => {
    state.isShowMobileSimpleNotification = false
  },
  // NOTE: 這裡只需要重置 isShowMobileNotification, isShowMobileSimpleNotification
  // 重置 state
  RESET_STATE: (state) => {
    Object.assign(state, {
      isShowMobileNotification: false,
      isShowMobileSimpleNotification: false
    })
  }
}

const actions = {
  setMobileDetect ({ commit }) {
    return new Promise((resolve) => {
      const mobileDetect = new MobileDetect(window.navigator.userAgent)
      commit('SET_MOBILE_DETECT', mobileDetect)
      resolve()
    })
  }
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}
