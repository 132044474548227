import Qs from 'qs'
import request from '@/utils/request'
import {
  JSON_CONFIG,
  FORM_CONFIG,
} from '@/utils/helper.js'

export function login (requestBody) {
  return request({
    url: '/front/medical/login',
    method: 'post',
    data: Qs.stringify(requestBody),
    header: FORM_CONFIG
  })
}

export function getProfile () {
  return request({
    url: '/front/medical/profile',
    method: 'get',
    header: JSON_CONFIG
  })
}

export function getPatientList () {
  return request({
    url: '/front/medical/patient/list',
    method: 'get',
    header: JSON_CONFIG
  })
}

export function getAllRecordList () {
  return request({
    url: '/front/medical/record/all',
    method: 'get',
    header: JSON_CONFIG
  })
}