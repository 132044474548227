import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify'

import VueCookies from 'vue-cookies'
import router from './routes'
import store from '@/stores'

// directives
import imageLazyLoad from './directives/image-lazy-load'

// lang settings
import i18n from '@/lang'

import conzianTimeHandler from '@/utils/conzian-time-handler.js'
import systemFuncs from '@/utils/system'

Vue.config.productionTip = false

Vue.directive('lazyload', imageLazyLoad)

Vue.use(VueCookies)
Vue.use(conzianTimeHandler)
Vue.use(systemFuncs)

new Vue({
  vuetify,
  router,
  store,
  i18n,
  render: h => h(App)
}).$mount('#app')
