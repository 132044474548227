import request from '@/utils/request'
import {
  JSON_CONFIG,
  getAPILangParam,
} from '@/utils/helper.js'


export function getHospitalByUser () {
  return request({
    url: '/misc/hospital',
    method: 'get'
  })
}

export function getRehabData () {
  return request({
    url: '/front/misc/rehab',
    method: 'get',
    header: JSON_CONFIG,
    params: {
      lang: getAPILangParam()
    }
  })
}
