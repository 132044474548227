import Vue from 'vue'
import VueI18n from 'vue-i18n'
import { getLanguageSetting } from '@/utils/helper.js'

// 自訂語言檔
import zhCN from './zh-CN.json'
import zhTW from './zh-TW.json'
import enUS from './en-US.json'

Vue.use(VueI18n)

const messages = {
  'zh-TW': zhTW,
  'zh-CN': zhCN,
  'en-US': enUS
}

// 取得預設語系
const locale = getLanguageSetting()

const i18n = new VueI18n({
  // 預設語言
  locale: locale,
  // 讀取不到 key 默認語言
  fallbackLocale: 'zh-TW',
  messages: messages
})

export default i18n
