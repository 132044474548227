export default {
  install: function (Vue) {
    // 掛載方法到 vue.prototype 子組件能以（ $ + 方法名 ）訪問
    for (var name in funcs) {
      Vue.prototype['$' + name] = funcs[name]
    }
  }
}

const funcs = {
  isProduction() {
    return process.env.VUE_APP_SERVER === 'production'
  },
  isDev() {
    return process.env.VUE_APP_SERVER === 'dev'
  },
  getSystemVersion() {
    let prefix = funcs.isProduction() ? '' : 'dev - '
    let version = process.env.VERSION
    return `${prefix}v${version}`
  },
  debounce(fn, delay = 1000) {
    let timer;
    return function () {
      window.clearTimeout(timer);
      timer = setTimeout(() => {
        fn.apply(this, arguments);
        window.clearTimeout(timer);
      }, delay);
    };
  },
  throttle(fn, delay = 1000) {	
    let run = true;	
    return function () {
      if (!run) {
        return;
      }
      let timer = setTimeout(() => {
        fn.apply(this, arguments);
        window.clearTimeout(timer);
        run = true;
      }, delay);

      run = false;
    }
  }
}
