import { login, getProfile } from '@/apis/user'
import { getToken, setToken, removeToken } from '@/utils/auth'
import router from '@/routes'

const getDefaultState = () => (
  {
    token: getToken(),
    id: '',
    name: '',
    type: 0,
    avatar: '',
    phone: '',
    email: '',
    hospital: '',
    fillSatisfaction: false
  }
)

const state = getDefaultState()

const getters = {
  token: state => state.token,
  loginUser: state => state
}

const mutations = {
  SET_USER: (state, user) => {
    state = Object.assign(state, user)
  },
  SET_TOKEN: (state, token) => {
    state.token = token
  },
  SET_FILLSATISFACTION: (state, fillSatisfaction) => {
    state.fillSatisfaction = fillSatisfaction
  },
  UPDATE_AVATAR: (state, avatar) => {
    state.avatar = avatar
  },
  // 重置 state
  RESET_STATE: (state) => {
    Object.assign(state, getDefaultState())
  }
}

const actions = {
  // user login
  login ({ dispatch }, userInfo) {
    const { email, password } = userInfo
    return new Promise((resolve, reject) => {
      login({ email: email.trim(), password: password })
        .then(response => {
          const { token } = response
          dispatch('setToken', token)
          resolve()
        })
        .catch(error => {
          reject(error)
        })
    })
  },

  // get user info
  getProfile ({ commit, dispatch }) {
    return new Promise((resolve, reject) => {
      getProfile()
        .then(response => {
          const { data } = response

          if (!data) {
            reject(new Error('[CZN error]: Profile API has no data !!'))
          }

          commit('SET_USER', data)
          resolve(data)
        })
        .catch(error => {
          commit('SET_USER', ERROR_USER)
          dispatch('logout')
            .then(() => {
              router.replace({ name: 'user-login' })
            })
          reject(error)
        })
    })
  },

  // // get user info
  // updateAvatar ({ commit }, imgBase64) {
  //   return new Promise((resolve, reject) => {
  //     updateAvatar({ avatar: formatBase64String(imgBase64) })
  //       .then(response => {
  //         const { data } = response

  //         commit('UPDATE_AVATAR', data.avatar)
  //         resolve(true)
  //       })
  //       .catch(error => {
  //         reject(error)
  //       })
  //   })
  // },

  // user logout
  logout ({ dispatch }) {
    return new Promise(resolve => {
      dispatch('resetToken')
      dispatch('resetAllState')
      resolve()
    })
  },

  // set token
  setToken ({ commit }, token) {
    return new Promise(resolve => {
      commit('SET_TOKEN', token)
      setToken(token)
      resolve()
    })
  },

  // remove token
  resetToken ({ commit }) {
    return new Promise(resolve => {
      commit('SET_TOKEN', '')
      removeToken()
      resolve()
    })
  },

  resetAllState ({ commit, rootState }) {
    return new Promise(resolve => {
      const modules = Object.keys(rootState)
      // 調用所有模塊底下的 RESET_STATE  mutations
      modules.forEach(key => {
        commit(`${key}/RESET_STATE`, null, { root: true })
      })
      resolve()
    })
  }
}

const ERROR_USER = {
  id: '',
  firstName: 'Error',
  lastName: '',
  type: 0,
  avatar: '',
  phone: 'Not Found',
  email: 'Not Found',
  hospital: 'Not Found'
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}

// function formatBase64String (base64) {
//   return base64.replace(/^data:.*?;base64,/, '')
// }
