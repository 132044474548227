import request from '@/utils/request'
import {
  JSON_CONFIG,
} from '@/utils/helper.js'

export function getPatientProfile (pid) {
  return request({
    url: `/front/medical/patient/${pid}`,
    method: 'get',
    header: JSON_CONFIG
  })
}

export function getPatientContestRecord (pid) {
  return request({
    url: `/front/medical/patient/${pid}/record`,
    method: 'get',
    header: JSON_CONFIG
  })
}

export function getPatientContestReport (pid, date, temId) {
  return request({
    url: `/front/patient/${pid}/report`,
    method: 'get',
    header: JSON_CONFIG,
    params: {
      date: date,
      temId: temId
    }
  })
}