import { getRehabData, getHospitalByUser } from '@/apis/misc'
import { getLanguageSetting } from '@/utils/helper.js'
import i18n from '@/lang'
import { _type } from '@/utils/type'

const getDefaultState = () => (
  {
    rehabs: [],
    plans: [],
    hospitalList: []
  }
)

const state = getDefaultState()

const getters = {
  rehabs: state => state.rehabs,
  plans: state => state.plans,
  hospitalList: state => state.hospitalList
}

const mutations = {
  SET_REHABS: (state, rehabs) => {
    state.rehabs = rehabs
  },
  SET_PLANS: (state, plans) => {
    state.plans = plans
  },
  SET_HOSPITAL_LIST: (state, hospitalList) => {
    state.hospitalList = hospitalList
  },
  // 重置 state
  RESET_STATE: (state) => {
    Object.assign(state, getDefaultState())
  }
}

const actions = {
  // get all exercises data and plans
  setRehabsAndPlans ({ commit }) {
    return new Promise((resolve, reject) => {
      const REHABS = JSON.parse(localStorage.getItem('rehabs'))
      const PLANS = JSON.parse(localStorage.getItem('plans'))

      // 判斷是否需要更新緩存
      if (isUpdateRehabsAndPlansCache(REHABS, PLANS)) {
        getRehabData()
          .then(response => {
            const { exercises = [], plans = [] } = response.data
            // TODO: 重構時考慮拿掉此 map
            const formatPlans = plans.map((plan) => {
              return {
                id: plan.planId,
                planTitle: plan.plan,
                planTitleEn: plan.planEn,
                rehabParents: plan.rehabParents
              }
            })

            // 考慮所有動作 api 返回為空的情況，因為後端緩存過期會直接返回空
            if (exercises.length === 0 && plans.length === 0) {
              reject(new Error('[CZN error]: exercises or plans is empty ! please check the response data.'))
            }

            commit('SET_REHABS', exercises)
            commit('SET_PLANS', formatPlans)
            setRehabsAndPlansCache(exercises, formatPlans)

            resolve()
          })
          .catch(error => {
            reject(error)
          })
      } else {
        commit('SET_REHABS', REHABS)
        commit('SET_PLANS', PLANS)
        resolve()
      }
    })
  },
  // get hospital list by login user's token
  setHospitalList ({ commit }) {
    return new Promise((resolve, reject) => {
      getHospitalByUser()
        .then(response => {
          const { data = [] } = response
          // NOTE: 暫時判斷型別塞值 觀察醫院是否仍有亂碼產生
          if (_type(data) === 'array') {
            if (data.length === 0) {
              reject(new Error('[CZN error]: hostital list is empty ! please check the response data.'))
            }

            commit('SET_HOSPITAL_LIST', data)
            resolve()
          } else if (_type(data) === 'object') {
            // NOTE: 暫時合併三個地區的醫院列表
            const hospitalList = entries(data).reduce((map, [, list]) => {
              return map.concat(list)
            }, [])

            commit('SET_HOSPITAL_LIST', hospitalList)
            resolve()
          }
        })
        .catch(error => {
          reject(error)
        })
    })
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}

function isUpdateRehabsAndPlansCache (rehabs = [], plans = []) {
  const rehabsLoadedLang = localStorage.getItem('rehabsLoadedLang')

  if (!rehabs || rehabs.length === 0) return true
  if (!plans || plans.length === 0) return true
  return rehabsLoadedLang !== getLanguageSetting()
}

function setRehabsAndPlansCache (rehabs, plans) {
  const DEFAULT_CACHE_TIME = 5 * 60 * 1000

  localStorage.setItem('rehabs', JSON.stringify(rehabs), DEFAULT_CACHE_TIME)
  localStorage.setItem('plans', JSON.stringify(plans), DEFAULT_CACHE_TIME)
  localStorage.setItem('rehabsLoadedLang', i18n.locale)
}

const entries = (obj) => {
  return Object.keys(obj || {})
    .map(key => ([key, obj[key]]))
}
