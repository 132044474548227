const TokenKey = 'access_token'

export function getToken () {
  return window.$cookies.get(TokenKey)
}

export function setToken (token) {
  return window.$cookies.set(TokenKey, token, '30d')
}

export function removeToken () {
  return window.$cookies.remove(TokenKey)
}

