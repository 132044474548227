import { getPatientContestRecord, getPatientContestReport } from '@/apis/patient.js'

const getDefaultState = () => (
  {
    contestList: [],
    selectedContestId: "",
    selectedContestRecord: {
      examType: ""
    },
    selectedContestReportList: {}
  }
)

const state = getDefaultState()

const getters = {
  getContestRecord: state => {
    return state.contestList
  },
  getSelectedContestId: state => {
    return state.selectedContestId
  },
  getSelectedContestRecord: state => {
    return state.selectedContestRecord
  },
  getSelectedContestReportList: state => {
    return state.selectedContestReportList
  },
}

const mutations = {
  SET_PATIENT_CONTEST_RECORD: (state, contestList) => {
    state.contestList = contestList
  },
  SET_SELECTED_CONTEST_ID: (state, contestId) => {
    state.selectedContestId = contestId
  },
  SET_SELECTED_CONTEST_RECORD: (state, contestId) => {
    state.selectedContestRecord = state.contestList.find(item => item.id === contestId)
  },
  RESET_SELECTED_CONTEST_RECORD: (state) => {
    state.selectedContestRecord = {}
  },
  SET_PATIENT_CONTEST_REPORT_LIST: (state, report) => {
    state.selectedContestReportList = report
  },
  RESET_PATIENT_CONTEST_REPORT_LIST: (state) => {
    state.selectedContestReportList = {}
  },
  // 重置 state
  RESET_STATE: (state) => {
    Object.assign(state, getDefaultState())
  }
}

const actions = {
  getPatientContestRecord({ commit }, pid) {
    return new Promise((resolve, reject) => {
      getPatientContestRecord(pid)
        .then(response => {
          const { data } = response

          data.sort((a, b) => {
            return b.updateTime - a.updateTime;
          });

          commit('SET_PATIENT_CONTEST_RECORD', data)

          resolve(response)
        })
        .catch(error => reject(error))
    })
  },
  getPatientContestReport({ commit }, { pid = "", date = "", temId = "" }) {
    return new Promise((resolve, reject) => {
      getPatientContestReport(pid, date, temId)
        .then(response => {
          const { data } = response

          commit('SET_PATIENT_CONTEST_REPORT_LIST', data)

          resolve(response)
        })
        .catch(error => reject(error))
    })
  },
  setSelectedContest({ commit, dispatch, state }, { contestId = "", pid = "", date = "", temId = "" }) {
    if (contestId === state.selectedContestId) {
      return
    }
    
    commit('RESET_SELECTED_CONTEST_RECORD')
    commit('RESET_PATIENT_CONTEST_REPORT_LIST')
    commit('SET_SELECTED_CONTEST_ID', contestId)
    
    return new Promise((resolve, reject) => {
      dispatch('getPatientContestReport', { pid, date, temId })
        .then((res) => {
          
          commit('SET_SELECTED_CONTEST_RECORD', contestId)
          resolve(res)
        })
        .catch(error => reject(error))
    })
  },
  // resetSelectedContest({ commit }) {
  //   commit('RESET_STATE')
  // }
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}