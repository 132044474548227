export const _type = function (obj) {
  const toString = Object.prototype.toString
  const typeMap = {
    '[object String]': 'string',
    '[object Number]': 'number',
    '[object Boolean]': 'boolean',
    '[object Array]': 'array',
    '[object Function]': 'function',
    '[object Null]': 'null',
    '[object Undefined]': 'undefined',
    '[object Object]': 'object',
    '[object Date]': 'date',
    '[object RegExp]': 'regExp'
  }
  return typeMap[toString.call(obj)]
}
