/*
 * @Author: sheng xiang
 * @Date: 2020-03-20 10:16:30
 * @LastEditTime: 2022-03-25 17:35:18
 * @LastEditors: Please set LastEditors
 * @Description: Conzian Vue plugin used for time
 * @FilePath: /KNEESUP-Med/src/util/conzian-time-handler.js
 */
import moment from 'moment-timezone'
import { getLanguageSetting, DEFAULT_TIMEZONE } from './helper'

export default {
  install: function (Vue) {
    // 掛載 moment 到 vue prototype 下
    Object.defineProperty(Vue.prototype, '$moment', {value: moment})

    // 定義初始 moment 語系
    moment.locale(getLanguageSetting())

    // 掛載方法到 vue.prototype 子組件能以（ $ + 方法名 ）訪問
    for (var name in funcs) {
      Vue.prototype['$' + name] = funcs[name]
    }
  }
}

const funcs = {
  /**
   * @description: 依照目前語言環境，拿到 相對時間
   * @param {*} timestamp: 時間戳
   * @return ex: 前天 昨天 明天
   */
  getRelativeTimeFromNow (timestamp) {
    if (shouldUnixTime(timestamp)) {
      return moment.unix(timestamp).fromNow()
    }
    return moment(timestamp).fromNow()
  },

  /**
   * @description: 依照目前語言環境，拿到 簡單的 時間格式
   * @param {*} timestamp: 時間戳
   * @param {*} timezone: 時區
   * @return ex: 2020/3/20 08:30
   */
  getSimpleDateAndTime (timestamp, timezone = DEFAULT_TIMEZONE) {
    if (!timezone) {
      timezone = DEFAULT_TIMEZONE
    }
    if (shouldUnixTime(timestamp)) {
      return moment.unix(timestamp).tz(timezone).format('YYYY/MM/DD HH:mm')
    }
    return moment(timestamp).tz(timezone).format('YYYY/MM/DD HH:mm')
  },

  /**
   * @description: 依照目前語言環境，拿到 簡單的 時間格式，不包含時間
   * @param {*} timestamp: 時間戳
   * @param {*} timezone: 時區
   * @return ex: 2020/3/20
   */
  getSimpleDateWithoutTime (timestamp, timezone = DEFAULT_TIMEZONE) {
    if (!timezone) {
      timezone = DEFAULT_TIMEZONE
    }
    if (shouldUnixTime(timestamp)) {
      return moment.unix(timestamp).tz(timezone).format('YYYY/MM/DD')
    }
    return moment(timestamp).tz(timezone).format('YYYY/MM/DD')
  },

  /**
   * @description: 依照目前語言環境，拿到 完整的(24時制) 時間格式
   * @param {*} timestamp: 時間戳
   * @param {*} timezone: 時區
   * @return ex: 2020年3月20日 08:30
   */
  getFullDateAndTime (timestamp, timezone = DEFAULT_TIMEZONE) {
    if (!timezone) {
      timezone = DEFAULT_TIMEZONE
    }
    let date = moment(timestamp).tz(timezone)
    if (shouldUnixTime(timestamp)) {
      date = moment.unix(timestamp).tz(timezone)
    }
    if (getLanguageSetting() === 'zh-TW') {
      return moment(date).format('YYYY年M月D日 HH:mm')
    }

    if (getLanguageSetting() === 'zh-CN') {
      return moment(date).format('YYYY年M月D日 HH:mm')
    }

    if (getLanguageSetting() === 'en-US') {
      return moment(date).format('LLL')
    }
  },

  /**
   * @description: 依照目前語言環境，回傳格式化後的時間格式(12時制)
   * @param {*} timestamp: 日期
   * @param {*} timezone: 時區
   * @return {*}: 回傳格式化後的時間格式 ex: 2020 年 3 月 20 日 凌晨 5:38
   */
  getPerfectTime (timestamp, timezone = DEFAULT_TIMEZONE) {
    if (!timezone) {
      timezone = DEFAULT_TIMEZONE
    }
    let date = moment(timestamp).tz(timezone)
    if (shouldUnixTime(timestamp)) {
      date = moment.unix(timestamp).tz(timezone)
    }
    if (getLanguageSetting() === 'zh-TW') {
      return moment(date).format('YYYY 年 M 月 D 日 A h:mm')
    }

    if (getLanguageSetting() === 'zh-CN') {
      return moment(date).format('YYYY 年 M 月 D 日 A h:mm')
    }

    if (getLanguageSetting() === 'en-US') {
      return moment(date).format('LLL')
    }
  },

  /**
   * @description: 依照目前語言環境，拿到 年月日 格式的時間
   * @param {*} timestamp: 時間戳
   * @param {*} timezone: 時區
   * @return ex: 2020年3月20日
   */
  getDateWithoutTime (timestamp, timezone = DEFAULT_TIMEZONE) {
    if (!timezone) {
      timezone = DEFAULT_TIMEZONE
    }
    if (shouldUnixTime(timestamp)) {
      return moment.unix(timestamp).tz(timezone).format('LL')
    }
    return moment(timestamp).tz(timezone).format('LL')
  },

  /**
   * @description: 依照目前語言環境，拿到 月日 格式的時間
   * @param {*} timestamp: 時間戳
   * @return ex: 3月 20日
   */
  getMonthAndDate (timestamp) {
    return moment(timestamp).format('MMM Do')
  },

  // /**
  //  * @description: 依照目前語言環境，拿到 月份
  //  * @param {*} timestamp: 時間戳
  //  * @return ex: 3月
  //  */
  // getMonth (timestamp) {
  //   return moment(timestamp).format('MMM')
  // },

  /**
   * @description: 依照目前語言環境，拿到 年月 格式的時間
   * @param {*} timestamp: 時間戳
   * @return ex: 3月
   */
  getYearAndMonth (timestamp) {
    if (getLanguageSetting() === 'zh-TW' || getLanguageSetting() === 'zh-CN') {
      return moment(timestamp).format('YYYY[年] MM[月]')
    }

    if (getLanguageSetting() === 'en-US') {
      return moment(timestamp).format('MMMM, YYYY')
    }
  },

  /**
   * @description: 獲取年份
   * @param {*} timestamp: 時間戳
   * @return ex: 2020
   */
  getYear (timestamp) {
    return moment(timestamp).year()
  },

  /**
   * @description: 依照目前語言環境，回傳格式化後的時間格式
   * @param {Number} timestamp: 日期
   * @param {String} timezone: 時區
   * @return {*}: 回傳格式化後的時間格式 ex: 晚上 8:00 開始
   */
  getRoundStartTime (timestamp, timezone = DEFAULT_TIMEZONE) {
    if (getLanguageSetting() === 'zh-TW') {
      return moment(timestamp).tz(timezone).format('A h:mm [開始]')
    }

    if (getLanguageSetting() === 'zh-CN') {
      return moment(timestamp).tz(timezone).format('A h:mm [开始]')
    }

    if (getLanguageSetting() === 'en-US') {
      return moment(timestamp).tz(timezone).format('[Start at] h:mm A')
    }
  },

  /**
   * @description: 依照目前語言環境，回傳格式化後的時間格式
   * @param {Number} timestamp: 日期
   * @param {String} timezone: 時區
   * @return {*}: 回傳格式化後的時間格式 ex: 晚上 8:00 結束
   */
  getRoundEndTime (timestamp, timezone = DEFAULT_TIMEZONE) {
    if (getLanguageSetting() === 'zh-TW') {
      return moment(timestamp).tz(timezone).format('A h:mm [結束]')
    }

    if (getLanguageSetting() === 'zh-CN') {
      return moment(timestamp).tz(timezone).format('A h:mm [结束]')
    }

    if (getLanguageSetting() === 'en-US') {
      return moment(timestamp).tz(timezone).format('[Finish at] h:mm A')
    }
  },

  /**
   * @description: 傳入時間，回傳傳入時間當週的日期
   * @param {*} timestamp: 日期
   * @return {Array} : ['20200320', '20200321' ...]
   */
  getWeekDateFromTime (time) {
    let weekOfday = moment(time).format('E')
    let week = []
    for (let index = 0; index < 7; index++) {
      const num = 1 + index
      week.push(
        moment(time).subtract(weekOfday - num, 'd').format('YYYYMMDD')
      )
    }
    console.log('week', week)
    return week
  },

  /**
   * @description: 回傳一個開始到結束的時間格式
   * @param {*} startTime: 開始時間
   * @param {*} endTime: 結束時間
   * @param {*} timezone: 時區
   * @return {String} : 2020/3/20 ~ 3/26
   */
  periodDateFormat (startTime, endTime, timezone = DEFAULT_TIMEZONE) {
    if (moment(startTime).tz(timezone).year() !== moment(endTime).tz(timezone).year()) {
      return `${moment(startTime).tz(timezone).format('YYYY/M/D')} ~ ${moment(endTime).tz(timezone).format('YYYY/M/D')}`
    } else {
      return `${moment(startTime).tz(timezone).format('YYYY/M/D')} ~ ${moment(endTime).tz(timezone).format('M/D')}`
    }
  },

  /**
   * @description: 判斷所傳日期是不是今天
   * @param {*} time: 時間，格式為：20200320
   * @param {*} timezone: 時區
   * @return {Boolean} return is today ?
   */
  isToday (time, timezone = DEFAULT_TIMEZONE) {
    return time === moment().tz(timezone).format('YYYYMMDD')
  },

  /**
   * @description: 判斷所傳日期是不是今天以後
   * @param {*} time: 時間，格式為：20200320
   * @param {*} timezone: 時區
   * @return {Boolean} return is date after today?
   */
  isDateAfterToday (time, timezone = DEFAULT_TIMEZONE) {
    return parseInt(time) > parseInt(moment().tz(timezone).format('YYYYMMDD'))
  },

  /**
   * @description: 判斷所傳日期是不是今天之前
   * @param {*} time: 時間，格式為：20200320
   * @param {*} timezone: 時區
   * @return {Boolean} return is date before today?
   */
  isDateBeforeToday (time, timezone = DEFAULT_TIMEZONE) {
    return parseInt(time) < parseInt(moment().tz(timezone).format('YYYYMMDD'))
  },

  getCusDateFormat (time, timezone = DEFAULT_TIMEZONE, getTime = false) {
    if (!timezone) {
      timezone = DEFAULT_TIMEZONE
    }
    const lang = getLanguageSetting()
    let option = (function () {
      switch (lang) {
        case 'zh-TW':
          return ({
            sameDay: '[今天] MoDo',
            nextDay: '[明天] MoDo',
            nextWeek: 'LL',
            lastDay: '[昨天] MoDo',
            lastWeek: 'LL',
            sameElse: 'LL'
          })
        case 'zh-CN':
          return ({
            sameDay: '[今天] MoDo',
            nextDay: '[明天] MoDo',
            nextWeek: 'LL',
            lastDay: '[昨天] MoDo',
            lastWeek: 'LL',
            sameElse: 'LL'
          })
        case 'en-US':
          return ({
            sameDay: '[Today] M/D',
            nextDay: '[Tomorrow] M/D',
            nextWeek: '[on] LL',
            lastDay: '[Yesterday] M/D',
            lastWeek: '[on] LL',
            sameElse: '[on] LL'
          })
      }
    }())
    if (getTime) {
      const keys = Object.keys(option)
      keys.forEach(item => {
        option[item] = option[item] + ' HH:mm'
      })
    }
    return moment(time).tz(timezone).calendar(null, option)
  },

  // NOTE: 預設時區暫時先用 台北時間
  isSameTimezone (timezone = null) {
    if (!timezone) return true
    return timezone === DEFAULT_TIMEZONE
  },

  // NOTE: 從生日換算年紀
  getAgeFromBirthday (birthday) {
    return moment().diff(moment(birthday, 'YYYY-MM-DD'), 'years')
  }
}

/**
 * @description: 判斷是不是該格式化時間（如果時間戳是秒）return true
 * @param {*} timestamp: 時間戳
 * @return {Boolean} return is sec
 */
function shouldUnixTime (timestamp) {
  return String(timestamp).length < 13
}
