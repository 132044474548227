import i18n from '@/lang'

export default [
  {
    path: '/patient/:id',
    // component: Patient,
    component: () => import('@/layouts/Patient/index'),
    meta: {
      title: i18n.t('router.patient.text_1'),
      requireAuth: true,
      isSupportMobile: true
    },
    children: [
      {
        name: 'patientRecord',
        path: '',
        // component: Summary,
        component: () => import('@/pages/Patient/Record'),
        meta: {
          title: i18n.t('router.patient.text_1'),
          requireAuth: true,
          isSupportMobile: true
        }
      },
      {
        name: 'patientReport',
        path: 'report',
        component: () => import('@/pages/Patient/Report'),
        meta: {
          title: i18n.t('router.patient.text_1'),
          requireAuth: true,
          isSupportMobile: true
        }
      },
    ]
  },
  
]
